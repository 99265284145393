import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import TableGrid from "../../components/UI/TableGrid";
import {
  stripHtmlTags,
  toQueryString,
  USDateTimeString,
} from "../../utils/commonUtils";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import WithRouter from "../../hoc/WithRouter";
import CptService from "../../services/CptService";
import AuditLogService from "../../services/AuditLogService";
import JsonViewModal from "../../components/Modal/JsonViewModal";
import Icons from "../../components/UI/Icons";

class AuditLogList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxChangeHandler,
      },
      {
        label: "Action",
        key: "action",
        sortName: "action",
        sortable: true,
      },
      {
        label: "Model Type",  
        key: "modelType", 
      },
      {
        label: "Changed Data",
        key: "changedData",
        type:'Actions'
      },
      {
        label: "Orginal Data",
        key: "originalData",
        type:'Actions'
      },
      {
        label: "Created At",
        key: "createdAt",
        sortable: true,
      },
      {
        label: "Updated At",
        key: "updatedAt",
        sortable: true,
      },
      {
        label: "Updated By",
        key: "updatedBy",
        sortable: true,
      },
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          status: 1000,
          data: [],
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "code",
          sort_order: "asc",
          search: {},
        },
      },
      activeEditId: null,
      isModelOpen:false,
      jsonViewData: null,
      
    };
  }

  componentDidMount() {
    this.fetchLogList();
    // Code to run after the component has mounted
  }

  componentDidUpdate = (prevProps, prevState) => {
    // console.log('logs',this.props);
    // Code to run after the component has updated
  };

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen, activeEditId,jsonViewData } = this.state;
    return (

      <>
        {
          isModelOpen ?
            <JsonViewModal
              data={jsonViewData}
              modelToggleHandler={this.addFormHandler}

            />
            :
            ''
        }
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0">Audit Log List </h6>
                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 px-1 d-flex">
                      <div className="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="AUDIT LOG"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                addFormHandler={() => this.addFormHandler()}
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchLogList(filters)
                }
                {...tableRecords}
              />
            </div>
          </div>
      </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search: filters,
    };
    this.fetchLogList(queryfilters);
  };
  clearFiltersHandler = async () => {
    await this.setState({
      filters: {},
    });
    this.submitFiltersHandler();
  };
  fetchLogList = async (query = {}) => {
    const { tableRecords } = this.state;
    try {
      const queryString = toQueryString({
        ...tableRecords.pagination,
        ...query,
      });
      this.props.Loader(true);
      const response = await AuditLogService.getAuditLogList(queryString);
      const tableData = response.data.data.map((auditLog, companyIndex) => {
        const createdAt = USDateTimeString(auditLog.created_at);
        const updatedAt = USDateTimeString(auditLog.updated_at);
        let actClr='blue';
        const action=auditLog.action.toUpperCase();
        if (action=='CREATE') {
          actClr='green';
        }
        if (action=='DELETE') {
          actClr='red';
        }        
        return {
          companyIndex: companyIndex,
          isHeading: false,
          rowId: auditLog.id,  
          data: {
            isActive: false, 
            action: auditLog.action.toUpperCase(),  
            modelType:auditLog.model_type_name,
            changedData: [{
              title: 'changedData',
              className:"text-sm",
              label: auditLog.changed_data&&auditLog.changed_data.slice(0, 25) + "....",
              clickHandler: () => this.setState({ isModelOpen: true, jsonViewData: auditLog.changed_data }),
              acl: "any",
            }],
            action: {
              title: action,
              value: <span class={`badge bg-${actClr}-soft text-${actClr}`}>{action}</span>
            },
            originalData: [{
              title: 'changedData',
              className:"text-sm",
              label: auditLog.original_data&&auditLog.original_data.slice(0, 25) + "....",
              clickHandler: () => this.setState({ isModelOpen: true, jsonViewData: auditLog.original_data }),
              acl: "any",
            }],    
            createdAt: createdAt, 
            updatedAt: updatedAt,
            updatedBy:auditLog.updated_by && auditLog.updated_by.first_name + " " +auditLog.updated_by.last_name
          },
        };
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            status: 200,
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: response?.data?.total,
          },
        },
      }));
    } catch (err) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200,
          },
        },
      });
      console.log("DataIsse");
      // console.error("Error fetching companies:", err);
    } finally {
      this.props.Loader(false);
    }
  };

  deleteHandler = async (id) => {
    CptService.deleteCpt(id)
      .then((res) => {
        this.fetchLogList();
        this.props.Toast.success("CPT Deleted Succesfully");
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  };
  editHandler = (rowId, data,) => {
    this.setState({
      activeEditId: rowId,
    }, this.addFormHandler())
  };
  checkBoxchangeHandler = (value, e) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}
export default WithRouter(withReduxState(WithRoutify(AuditLogList)));
