import React, { useEffect, useState } from "react";
import FormInputs from "../../FormInputs";
import Icons from "../../Icons";
import { isObjectEmpty, isSet } from "../../../../utils/commonUtils";
import FieldLoader from "../../FieldLoader";
import Button from '../../Buttons';

const FieldEdit = (props) => {
    // console.log('onEditProps', props);
    const { rowId, onSave, thHeadKey,isAction } = props;
    const [isHovered, setIsHovered] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [validation, setValidation] = useState('');
    useEffect(()=>{
        setValue(props.value.value || '');
    },[props.value.value]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    }
    const handleMouseLeave = () => {
        setIsHovered(false);
    }
    const handleEditClick = () => setIsEditing(true);
    const handleInputChange = (val, e) => {
        // setValue(val);
        updateEdit(val);
    }
    const handleBlur = () => {
        // setIsEditing(false);
        // setValue(props.value.value)

    };
    const CancelEdit = () => {
        setIsEditing(false);
        setValue(props.value.value)
    }
    const updateEdit =async (val) => {
        setIsLoading(true);
        const validationResponse =await onSave(thHeadKey, rowId, val);
        setValue(val);
        if (!isObjectEmpty(validationResponse)) {
             let validation=validationResponse[thHeadKey] || '';
             setValidation(validation);
        }
        else{
            setIsEditing(false);
            setValidation('') 
        }
        setIsLoading(false);
        console.log(' updateEdit response',validationResponse);
    }

    return (
        <td
            className="forTable_textEllipsis"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            key={"column"}
        >
            {isEditing ? (
                <>
                    <FieldLoader isLoading={isLoading}>
                        <div className="d-flex align-items-center">
                            <FormInputs
                                id={rowId}
                                options={props?.options}
                                type={'SelectList'}
                                name={props?.thHeadKey}
                                changeHandler={handleInputChange}
                                className="form-control"
                                value={value}
                                onBlur={handleBlur}
                            />
                            <div className="">
                                {/* <a className="btn btn-icon btn-transparent-dark mr-1" title={'Update'} onClick={updateEdit}>
                                    <Icons type={'Update'} />
                                </a> */}
                                <a className="btn btn-icon btn-transparent-dark " title={'Cancel'} onClick={CancelEdit}>
                                    <Icons type={'Cancel'} />
                                </a>
                            </div>
                        </div>
                        <span className="text-danger m-1">
                            {isSet(validation, "")}
                        </span>
                    </FieldLoader>
                    
                </>
            ) : (
                    <>
                        {isHovered && !isEditing ? (
                            <a
                                href="#!"
                                id={rowId}
                                onClick={handleEditClick}
                                style={{
                                    color: "blue",
                                    textDecoration: "none",
                                }}
                            >
                                <Icons type="Edit" />
                                <span>{value}</span>
                            </a>
                        ) : isAction ? (
                            <Button {...props.value} />
                        ) : (
                            <a
                                href="#!"
                                id={rowId}
                                onClick={handleEditClick}
                                style={{
                                    color: "blue",
                                    textDecoration: "none",
                                    ...props.style
                                }}
                            >
                                <span>{value}</span>
                            </a>
                        )}
                    </>
            )}
        </td>
    );
};

FieldEdit.defaultProps = {
    clickHandler: () => { },
    onSave: () => { },
    isAction:false,
};

export default FieldEdit;

// {
//     className: "btn btn-icon btn-transparent-dark ml-2  ",
//     iconType: "Billing",
//     acl: PERMISSIONS.VISITS.EDIT,
//     iconSize:18,
//     iconColor:getBillingStatusColor(visit.billing_status),
//     title: "Billing",
    
//     clickHandler: () => {this.billingStatusHandler(visit.visit_id)},
//   },