import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {TaxIdFormatter, getBase64String, getCurrentDate, getOrganizationId, isNumber, isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import CompanyService from "../../services/CompanyService";
import ClinicService from "../../services/ClinicService";
import { CREATED, OK , SALES_REP, WORK_COMP_INSURANCE} from "../../utils/constant";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import { useSelector } from "react-redux";
import TableGrid from "../../components/UI/TableGrid";
import moment from "moment";
import SectionLoader from "../../components/UI/SectionLoader";
import { organizationList } from "../../store/OrganizationSlice";
import DropDownService from "../../services/DropDownService";
import LookupService from "../../services/LookupService";
import { convertUnderScoreStrings } from "../../utils/commonUtils";
import { FieldValidationFocus } from "../../utils/cssUtils";

const AddCompany = (props) => {
  const stateList = useSelector((state) => state.session.profileStateList);
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [clinicOptions, setClinicOptions] = useState([]);
  const [salesRepOptions, setSalesRepOptions] = useState([]);
  const [workCompInsuranceOptions, setWorkCompInsuranceOptions] = useState([]);
  const { isOpen, addFormHandler, modelTitle, organizationOption, activeEditId, Loader } = props;
  const [DocumentList, setDocumentList] = useState({ tableHeaders: [], tableRows: { data: [] } });
  const [DocumentSectionLoader, setDocumentSectionLoder] = useState(false);
  const [organizationListOptions,setOrganizationListOptions] = useState([]);
  const [isCopyFromCompanyChecked, setIsCopyFromCompanyChecked] = useState(false);
  const inputFields=getInputFields();

  useEffect(() => {
    //initil render 
    const fetchAndUpdateFormData = async () => {
      Loader(true);
      await fetchWorkCompInsuranceOptions();
      await fetchSalesRepOptions();
      let fields = await getInputFields();
      let updatedFormData = { ...formData };
      if (activeEditId) {  
        const response = await CompanyService.getCompany(null,activeEditId);
        fetchDocumentList();
        updatedFormData = { ...formData, ...response };
      }else{
        fetchOrganizationOptions('',()=>{});
        updatedFormData['send_drug_screen_results_to_employees'] = 0;
        updatedFormData['send_lab_results_to_employees'] = 0;
        updatedFormData['send_physical_results_to_employees'] = 0;
      }
      // Update the state with the modified form data
      updatedFormData['start_date'] = updatedFormData['start_date'] ? updatedFormData['start_date'] : getCurrentDate();
      setFormData(updatedFormData);
      Loader(false);
    }
    fetchAndUpdateFormData();
  }, []);



  const fetchOrganizationOptions = async (val,callback) => {
    console.log();
    let optionsArr = [{ label: "-Select Organization-", value: '' }];
    let query = '';
    if(isSet(val)){
      query={'search':val};
    }
    try {
      const data = await DropDownService.getOrganizationDropDownList(query);
      
      data.data.forEach(itm => {
        optionsArr.push({ label: convertUnderScoreStrings(itm.name), value: itm.organization_uuid });
        });
      if (optionsArr.length === 0) {
        setOrganizationListOptions([]);
      } else {
        setOrganizationListOptions(optionsArr);
      }

      
    } catch (error) {
    
    } finally {
      
    }
    
    callback(optionsArr);
  };

  //when clinic option changes for global admin
  useEffect(() => {
      let fields =  getInputFields();
  }, [clinicOptions,salesRepOptions]);

  //when org option changes for global admin
  useEffect(() => {
    fetchClinic();
  }, [formData.organization]);

  //fetch clinic
  const fetchClinic = async () => {
    let opt = [];
    let clinicResponse = await DropDownService.getClinicList(isSet(formData.organization, null));
    if(clinicResponse && clinicResponse.length !== 0){
      opt.push({ label: "ALL", value: "ALL" });
      clinicResponse?.forEach((itm, ind) => {
        opt.push({ label: itm.name, value: itm.clinic_id });
      });
    }
    setClinicOptions(opt);
  }
  useEffect(() => {
    fetchSalesRepOptions();
  }, [formData.organization]);

  const fetchSalesRepOptions = async () => {
    let opt = [{ label: "-Select-", value: "" }]
    const response = await ClinicService.getSalesRep(isSet(formData.organization, null));
    console.log(response);
    if (response) {
      response.forEach((itm) => {
        opt.push({ label: itm.sales_rep, value: itm.sales_rep });
      });
    }
    setSalesRepOptions(opt);
  }

  const fetchWorkCompInsuranceOptions = async () => {
    let opt = [{ label: "-Select-", value: "" }]
    let response = await DropDownService.getWorkCompDropDown();
    if(response){
      response?.forEach((itm, ind) => {
        opt.push({ label: itm.name, value: itm.id });
      });
    }
    setWorkCompInsuranceOptions(opt);
  }
  //fetch document list
  const fetchDocumentList = async () => {
    const documents = await CompanyService.getCompanyDocument(activeEditId);
    const tableData = documents.map((item) => ({
      rowId: item.id,
      data: {
        name: item.file_manager,
        updatedAt: moment.utc(item.file_manager.updated_at).format('MMMM D, YYYY h:mm:ss A'),
        action: [{
          className: 'btn btn-icon btn-transparent-dark',
          iconType: 'Remove',
          acl: 'company-delete',
          title: 'Delete',
          isDelete: true,
          clickHandler: () => deleteDocument(item.id),
        }]
      }
    }));
    setDocumentList({ tableHeaders: getTableHeaders(), tableRows: { data: tableData } });

  }

  const changeHandler = async (value, fieldName, { addressParam = {}, selectVal }) => {
    let updatedFormData = { ...formData };
    let updateValidation = { ...validation };

    // Handle the checkbox change
    if (fieldName === 'copy_from_company') {
      if (isSet(formData.address, false) == false) {
        setValidation({
          ...updateValidation,
          ["copy_from_company"]: "Company Address Required to Copy Address",
        });
        return false;
      }

        console.log(value);
        if(value === 1){
          updatedFormData["accounts_payable_address"] = isSet(formData.address, "");
          updateValidation["accounts_payable_address"] = "";
          updatedFormData["accounts_payable_city"] = isSet(formData.city, "");
          updateValidation["accounts_payable_city"] = "";
          updatedFormData["accounts_payable_state"] = isSet(formData.state, "");
          updateValidation["accounts_payable_state"] = "";
          updatedFormData["accounts_payable_postal_code"] = isSet(formData.postal_code, "");
          updateValidation["accounts_payable_postal_code"] = "";
          updatedFormData["accounts_payable_contact_name"] = isSet(formData.name, "");
          updateValidation["accounts_payable_contact_name"] = "";
          updatedFormData["accounts_payable_contact_phone"] = isSet(formData.phone, "");
          updateValidation["accounts_payable_contact_phone"] = "";
        }else{
          updatedFormData["accounts_payable_address"] = "";
          updatedFormData["accounts_payable_city"] = "";
          updatedFormData["accounts_payable_state"] = "";
          updatedFormData["accounts_payable_postal_code"] = "";
          updatedFormData["accounts_payable_contact_name"] = "";
          updatedFormData["accounts_payable_contact_phone"] = "";
        }
        setIsCopyFromCompanyChecked(!isCopyFromCompanyChecked);
      // // If checked, copy address details from organization
      // if (value) {
      //   updatedFormData = {
      //     ...updatedFormData,
      //     address: formData.address,
      //     city: formData.city,
      //     postal_code: formData.postal_code,
      //     state: formData.state,
      //     county: formData.county,
      //   };
      // } else {
      //   // Clear the address details if unchecked
      //   updatedFormData = {
      //     ...updatedFormData,
      //     address: '',
      //     city: '',
      //     postal_code: '',
      //     state: '',
      //     county: '',
      //   };
      // }
    }
  
    // Handle other field changes
    if (fieldName === "address") {
      if (addressParam.street) {
        updatedFormData["address"] = addressParam.street;
        updateValidation["address"] = "";
      }
      if (addressParam.state) {
        updatedFormData["state"] = addressParam.state;
        updateValidation["state"] = "";
      }
      if (addressParam.zipcode) {
        updatedFormData["postal_code"] = addressParam.zipcode;
        updateValidation["postal_code"] = "";
      }
      if (addressParam.city) {
        updatedFormData["city"] = addressParam.city;
        updateValidation["city"] = "";
      }
      if (addressParam.county) {
        updatedFormData["county"] = addressParam.county;
        updateValidation["county"] = "";
      }
    }
  //acounts payable address
  if (fieldName == "accounts_payable_address") {
    if (isSet(addressParam.street, false)) {
      updatedFormData["accounts_payable_street"] = addressParam.street;
      updateValidation["accounts_payable_street"] = "";
    }
    if (isSet(addressParam.state, false)) {
      updatedFormData["accounts_payable_state"] = addressParam.state;
      updateValidation["accounts_payable_state"] = "";
    }
    if (isSet(addressParam.zipcode, false)) {
      updatedFormData["accounts_payable_postal_code"] = addressParam.zipcode;
      updateValidation["accounts_payable_postal_code"] = "";
    }
    if (isSet(addressParam.city, false)) {
      updatedFormData["accounts_payable_city"] = addressParam.city;
      updateValidation["accounts_payable_city"] = "";
    }
  }
    // Handle document change
    if (fieldName === 'company_document') {
      if (value) {
        const { base64WithoutPrefix, fileName } = await getBase64String(value);
        updatedFormData["company_documents_base64"] = base64WithoutPrefix;
        updatedFormData["company_documents_filename"] = fileName;
      } else {
        updatedFormData["company_documents_base64"] = '';
        updatedFormData["company_documents_filename"] = '';
      }
    }
  
    if (fieldName === 'clinic_id') {
      value = handleClinicSelection(value, selectVal, formData, clinicOptions,fieldName);
    }
  
    if (fieldName === "tax_id") {
      value = TaxIdFormatter(value);
    }
  
    updatedFormData[fieldName] = value;
    updateValidation[fieldName] = '';
    setFormData(updatedFormData);
    setValidation(updateValidation);
  };
  
  const formSubmitHandler = async () => {
    //validation add submit goes here

    let updateValidation = {};
    //check if global admin 
    if (isSet(props.profile.is_multiple_organization, false) === true && organizationListOptions.length > 2&&!activeEditId) {
      //check organization filled if no throwing validation
      if (isSet(formData.organization, false) == false) {
        setValidation({
          ...updateValidation,
          ['organization']: 'The Organization Field is Required',
        });
        return false
      }
    }
    let res;
    //update or submit
    if (activeEditId) {
      res = await CompanyService.updateCompany(activeEditId, formData)
    }
    else {
      res = await CompanyService.saveCompany(isSet(formData.organization, null), formData)
    }
    if (res.code == OK || res.code == CREATED) {
      props.fetchCompaniesList();
      addFormHandler();
      let message = `Company ${activeEditId ? 'Updated' : 'Added'} Successfully`
      props.Toast.success(message);
      props.clearDDStateByKey('company');
    } else if (res.status && res.status == "ERROR") {
      if (isObject(res.data)) {
        for (let key in res.data) {
          updateValidation = {
            ...updateValidation,
            [key]: res.data[key].join(","),
          };
        }
      } else {
        props.Toast.error(res.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
        FieldValidationFocus(inputFields, updateValidation);
        //accorion focus
        // const flatData = inputFields.flatMap(item => item.data.flat());
        // let arr1= flatData.map((i)=>i.name);
        // const sortedArray = Object.keys(updateValidation).sort((a, b) => arr1.indexOf(a) - arr1.indexOf(b)); //sort error input      
        // const firstErrorField = sortedArray[0]; //1st
        // const firstErrorElement = document.getElementById(firstErrorField);
        // if (firstErrorElement) {
        //   const collapseId = firstErrorElement.closest('.collapse').id;
        //   const accordionElement = document.getElementById(collapseId);
        //   if (accordionElement && !accordionElement.classList.contains('show')) {
        //     const accordionToggle = document.querySelector(`a[href="#${collapseId}"]`); //open accord if closed
        //     accordionElement.classList.add('show');
        //     accordionToggle?.setAttribute('aria-expanded', 'true');
        //   }
        //   firstErrorElement.focus(); //
        // }
      }
    }
  };
  const submitDocument = async () => {
    const { company_document } = formData;
    const payload = new FormData();
    payload.append('company_document', company_document);
    payload.append('company_id', activeEditId);
    setDocumentSectionLoder(true);
    const response = await CompanyService.addCompanyDocument(payload);
    setDocumentSectionLoder(false);
    let updateValidation = { ...validation };
    if (response.code == OK || response.code == CREATED) {
    } else if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      } else {
        props.Toast.error(response.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    }
    //update form data
    const updatedFormData = { ...formData };
    updatedFormData["company_document"] = '';
    updatedFormData["company_documents_base64"] = '';
    updatedFormData["company_documents_filename"] = '';
    setFormData(updatedFormData);
    fetchDocumentList();
  }
  const deleteDocument = async(id) => {
    setDocumentSectionLoder(true);
    const deleteDocumet = await CompanyService.deleteCompanyDocument(id);
    props.Toast.success("Document Deleted Successfully");
    fetchDocumentList();
    setDocumentSectionLoder(false);
  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        size="xl"
        dialogClassName="hResponsive "
        contentClassName="h-100"
        className=""
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{modelTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body tab-content p-0">
              {inputFields &&
                inputFields.map((section, sectionIndex) => {
                  return (
                    <>
                      <div className="card mb-2">
                        <a
                          href={`#collapseCardExample_${sectionIndex}`}
                          className="d-block sh_accordionheader card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls="collapseCardExample"
                          style={{
                            position: "sticky",
                            top: "-15px",
                            zIndex: "1",
                          }}
                        >
                          <h6 className="m-0 text-md">
                            {section.title}
                          </h6>
                        </a>
                       
                        <div
                          className="collapse show"
                          id={`collapseCardExample_${sectionIndex}`}
                          data-bs-parent="#modal111"
                        >
                          <div className="card-body">
                            <div className="row">
                              {section.data && section.data.map((data, dataInd) => {
                                return data && (
                                  <>
                                    <div className={isSet(data.col,"col-lg-4 col-6")}>

                                    <div className={`form-group ${isSet(data.isLabelAppend, false) === true ? 'form-check form-check-solid' : ''}`} >
                                      {(isSet(data.isLabelAppend, false) === false) &&
                                        <label className="mb-0 text-sm opacity-75 text-truncate">
                                          {data.label}
                                        </label>}

                                      
                                        <div
                                        className={data.type=='MultiSelectDropDown'?'d-flex muliselect-validation':''}
                                          key={dataInd}
                                        >
                                          <FormInputs
                                            {...data}
                                            id={isSet(data.id,data.name)}
                                            value={isSet(formData[data.name], "")}
                                            className={isSet(data.className,"form-control form-control-solid")
                                              
                                            }
                                            changeHandler={(value, e) =>
                                              changeHandler(value, data.name, e)
                                            }
                                            validationMsg={isSet(validation[data.name], "")}
                                          />
                                          {(isSet(data.isLabelAppend, false) === true) &&
                                            <label className="form-check-label" htmlFor={data.name}>
                                              {data.label}
                                            </label>}
                                          
                                        </div>
                                        {isSet(data?.hideValidationMsg, false) == false && isSet(validation[data.name], "") !==
                                          "" ? (
                                          <span className="text-danger m-1">
                                            {isSet(validation[data.name], "")}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              {
                activeEditId ?
                  <div className="card mb-2">
                    <a
                      href={`#collapseCardExample_${9}`}
                      className="d-block sh_accordionheader card-header"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseCardExample"
                      style={{
                        position: "sticky",
                        top: "0px",
                        zIndex: "1",
                      }}
                    >
                      <h6 className="m-0 text-md font-weight-bold">{"Company Documents (Contracts or Other Items)"}</h6>
                    </a>
                    <div
                      className="collapse show"
                      id={`collapseCardExample_${9}`}
                      data-bs-parent="#modal111"
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="sh_innerPortview w-100 d-flex">
                            <div className="col-6">
                              <div className="sh_cardBox h-auto shadow-none border">
                                <div className="sh_cardBody p-3">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label className="f-500 text-md text-truncate mb-0">
                                          {"Document"}
                                        </label>
                                        <FormInputs
                                          type="ImageUpload"
                                          title="Field Document"
                                          className=""
                                          name="company_document"
                                          value={{
                                            original_name: formData['company_documents_filename'] || '',
                                            base64: formData['company_documents_base64'] || ''
                                          }}
                                          changeHandler={(val, e) => { changeHandler(val, 'company_document',{e}) }}
                                          accept={"image/png, image/gif, image/jpeg"}
                                        />
                                      </div>
                                      {isSet(validation['company_document'], "") !==
                                        "" ? (
                                        <span className="text-danger m-1">
                                          {isSet(validation['company_document'], "")}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="sh_cardFooter justify-content-center">
                                  <button className="btn btn-primary btn-sm text-sm" onClick={() => submitDocument()}>
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="sh_cardBox h-auto shadow-none border">
                                <div className="sh_cardHeader">
                                  <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                                    <h6 className="text-md mb-0">Company Documents</h6>
                                  </div>
                                </div>
                                <div className="sh_cardBody documentcontainer p-0">
                                  <TableGrid {...DocumentList} />
                                  <SectionLoader isLoading={DocumentSectionLoader} />

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  ""
              }
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              className=""
              onClick={() => formSubmitHandler()}
            >
              {activeEditId ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
   function getInputFields() {
    const inputFields = [
      {
        title: "Company - Address & Contact",
        data: [
          {
            type: "TextInput",
            label: "Name",
            name: "name",
            placeholder: "Name",
            className: "form-control form-control-solid",
            isFirstLetterCaps: true,
            required: true,
          },
          {
            type: "TextInput",
            label: "Code",
            name: "code",
            placeholder: "Code",
            className: "form-control form-control-solid",
            // allUpperCase: true,
            // max:5,
          },
          {
            type: "Address",
            label: "Address",
            name: "address",
            placeholder: "Address",
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "TextInput",
            label: "City",
            name: "city",
            placeholder: "City",
            isFirstLetterCaps: true,
            required: true,
          },
          {
            type: "SelectList",
            options: stateList,
            label: "State",
            name: "state",
            placeholder: "State",
            isFirstLetterCaps: true,
            required: true,
          },
          {
            type: "ZipCode",
            label: "Zip+4",
            name: "postal_code",
            placeholder: "zip+4",
            required: true,
          },
          {
            type: "TextInput",
            label: "Location",
            name: "location",
            placeholder: "Location",
            isFirstLetterCaps: true,
          },
          {
            type: "TextInput",
            label: "County",
            name: "county",
            placeholder: "County",
            isFirstLetterCaps: true,
          },
          {
            type: "Phone",
            label: "Phone",
            name: "phone",
            className: "form-control form-control-solid",
            placeholder: "Phone",
            required: true,
          },
          {
            type: "Phone",
            label: "Fax",
            name: "fax",
            placeholder: "Fax",
            max: 10
          },
          {
            type: "TextInput",
            label: "General Email",
            // col:'col-6',
            name: "general_email",
            placeholder: "Email",
          },
          {
            type: "MultiSelectDropDown",
            col:'col-12',
            options: clinicOptions,
            label: "Assign Clinic",
            name: "clinic_id",
            required: true,
            hideValidationMsg:true,
          },
        ],
      },
      {
        title: "Company - ID",
        data: [
          activeEditId ? {
            type: "NonEdit",
            label: "System ID",
            name: "system_id",
            placeholder: "System ID",
            value: formData["system_id"] || "--",
            required: true,
          } : '',
          {
            type: "",
            label: "EMR Interface ID",
            name: "emr_interface_id",
            placeholder: "EMR Interface ID",
            // required: true,
          },
          {
            type: "TextInput",
            label: "Nickname",
            name: "nickname",
            placeholder: "Nickname",
            max: 5,
          },
          {
            type: "Number",
            label: "Federal Tax ID",
            name: "tax_id",
            placeholder: "Federal Tax ID",
          },
          {
            type: "Date",
            label: "Start Date",
            name: "start_date",
            placeholder: "Start Date",
            value: getCurrentDate(),
          },
        ],
      },
      {
        title: "Company - Insurance",
        data: [
          {
            type: "SelectList",
            options:workCompInsuranceOptions,
            label: "Work Comp Insurance",
            name: "work_comp_insurance",
            placeholder: "Work Comp Insurance",
          },
          {
            type: "TextInput",
            label: "Work Comp Policy Number",
            name: "work_comp_policy_number",
            placeholder: "Work Comp Policy Number",
          },
          {
            type: "Date",
            label: "Work Comp Policy Expires",
            name: "work_comp_policy_expires",
            placeholder: " Work Comp Policy Expires",
            isFirstLetterCaps: true,
          },
        ],
      },      
      {
        title: "Employer - Results Notifications",
        data: [
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Physical Results Email",
            name: "physical_results_email",
            placeholder: "Physical Results Email",
            className: "form-control form-control-solid",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Drug Screen Results Email",
            name: "drug_screen_results_email",
            placeholder: "Drug Screen Results Email",
          }, 
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Lab Results Email",
            name: "lab_result_email",
            placeholder: "Lab Results Email",
          },
                 
          {
            type: "Tag",
            tagInputType:'Phone',
            label: "Physical Results SMS Text",
            name: "physical_results_sms_text",
            placeholder: "Physical Results SMS Text",
            isNumber:true,
            maxSize:10,
          },
          {
            type: "Tag",
            tagInputType:'Phone',
            label: "Drug Screen Results SMS Text",
            name: "drug_screen_results_sms_text",
            placeholder: " Drug Screen Results SMS Text",
            isNumber:true,
            maxSize:10,
          },  
          {
            type: "Tag",
            tagInputType:'Phone',
            label: "Lab Results SMS Text",
            name: "lab_result_sms_text",
            placeholder: "Lab Results SMS Text",
            isNumber:true,
            maxSize:10,
          },
             
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Missing Claim Email",
            name: "missing_claim_email",
            placeholder: "Missing Claim Email",
          },
          {
            type: "Tag",
            tagInputType:'Phone',
            label: "Missing Claim SMS Text",
            name: "missing_claim_sms_text",
            placeholder: "Missing Claim SMS Text",
            isNumber:true,
            maxSize:10,
          },
        ],
      },
      {
        title: "Employee - Results Notifications",
        data: [
          {
            type: "YesOrNo",
            label: "Send Physical Results to Employees",
            name: "send_physical_results_to_employees",
            placeholder: "Send Physical Results to Employees",
            required: true,
          },
          {
            type: "YesOrNo",
            label: "Send Drug Screen Results to Employees",
            name: "send_drug_screen_results_to_employees",
            placeholder: "Send Drug Screen Results to Employees",
            required: true,
          }, 
          {
            type: "YesOrNo",
            label: "Send Lab Results to Employees",
            name: "send_lab_results_to_employees",
            placeholder: "Send Lab Results to Employees",
            required: true,
          },
               
        ],
      },
      {
        title: "Company - Invoice Notifications",
        data: [
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Invoice Email",
            name: "invoice_email",
            placeholder: "Invoice Email",
          },
          {
            type: "Tag",
            tagInputType:'Phone',
            label: "Invoice SMS Text",
            name: "invoice_sms_text",
            placeholder: "Invoice SMS Text",
            isNumber:true,
            maxSize:10,
          },
        ],
      },
      {
        title: "Company - Accounts Payable Address",
        data: [
          {
            type:'Checkbox',
            isLabelAppend: true,
            className: "form-check-input",
            name: "copy_from_company",
            id: "copy_from_company",
            label:'Copy same Address from Company',
            value:{isCopyFromCompanyChecked},
            col:'col-12',
          },
          {
            type: "Address",
            label: "Accounts Payable Address",
            name: "accounts_payable_address",
            placeholder: "Accounts Payable Address",
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "TextInput",
            label: "Accounts Payable City",
            name: "accounts_payable_city",
            placeholder: "Accounts Payable City",
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "SelectList",
            options: stateList,
            label: "Accounts Payable State",
            name: "accounts_payable_state",
            placeholder: "Accounts Payable State",
            required: true,
          },
          {
            type: "ZipCode",
            label: "Accounts Payable Zip+4",
            name: "accounts_payable_postal_code",
            placeholder: "Accounts Payable Zip+4",
            required: true,
          },
          {
            type: "TextInput",
            label: "Accounts Payable Contact Name",
            name: "accounts_payable_contact_name",
            placeholder: "Accounts Payable Contact Name",
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "Phone",
            label: "Accounts Payable Contact Phone",
            name: "accounts_payable_contact_phone",
            placeholder: "Accounts Payable Contact Phone",
            required: true,
          },
        ],
      },
      {
        title: "Company - Employer Special Instructions",
        data: [
          {
            col: 'col-12',
            type: "Editor",
            label: "Employer Special Instructions",
            name: "employer_special_instructions",
            placeholder: "Employer Special Instructions",
          },
        ],
      },
      {
        title: "Company - Contracts & Pricing",
        data: [
          {
            type: "SelectList",
            options:salesRepOptions,
            label: "Sales Rep",
            name: "sales_rep",
            placeholder: "Sales Rep",
            required: true,
      
          },
          {
            type: "Number",
            label: "Contracted Pricing",
            name: "contracted_pricing",
            placeholder: "Contracted Pricing",
            decimalAllowed:true,
            required: true,
            max:12
          },
          {
            type: "TextInput",
            label: "Purchase Order",
            name: "purchase_order",
            placeholder: "Purchase Order",
          },
        ],
      },
    ];
    if (isSet(props.profile.is_multiple_organization, false) === true  && !activeEditId) {
      inputFields.unshift(
        {
          title: "Organization Details",
          data: [
            {
              type: "SelectSearchList",
              label: "Organization",
              options: organizationListOptions,
              placeholder: "",
              className: "form-control form-control-solid",
              name: "organization",
              required:true,
              isAsync: true,
              isCacheNeeded:false,
              loadCallBack: (val, callback) => fetchOrganizationOptions(val,callback),

            },
          ],
        },
      );
    }
    return inputFields;
  }
  function getTableHeaders() {
    const header = [
      { label: 'File Name', key: 'name', type: 'FileType' },
      { label: 'Updated Date', key: 'updatedAt', type: '' },
      { label: 'Action', key: 'action', type: 'Actions' },
      // Add more columns as needed
    ];
    return header;
  }
  function handleClinicSelection(value, selectVal, formData, clinicOptions, fieldName) {
    console.log('value',value,'selectVal',selectVal);
    if (value) {
      if (selectVal === 'ALL') {
        value = 'ALL';
      } else {
        let data = formData[fieldName]?.split(',') || [];
        if (!data.includes(selectVal)) {
          data.push(selectVal);
        }
        value = data.join(',');
      }
    }
    else {
      if (formData[fieldName] === 'ALL') {
        if (selectVal != 'ALL') {
          let removeClinics = clinicOptions
            .filter(fil => fil.value !== selectVal && fil.value !== 'ALL')
            .map(item => item.value);

          console.log('removeClinics', removeClinics);
          value = removeClinics.join(',');
        }
        else {
          value = '';
        }

      } else {
        let data = formData[fieldName].split(',') || [];
        let index = data.indexOf(selectVal);

        if (index !== -1) {
          data.splice(index, 1);
        }
        value = data.join(',');
      }
    }

    if (value) {
      let lengthCheck = value.split(',').filter(fil=>fil!='');
      console.log('lengthCheck',lengthCheck, lengthCheck.length, value);

      if (clinicOptions.length - 1 === lengthCheck.length) {
        value = 'ALL';
      }
    }
    return value;
  }
};
export default withReduxState(WithRoutify(AddCompany));
