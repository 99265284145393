// import { _t } from "../utils/i18nUtils";

//NETWORK RESPONSE
export const OK = 200;
export const CREATED = 201;
export const NO_CONTENT = 204;
export const BAD_REQUEST = 400;
export const UNAUTHORIZED =401;
export const FORBIDDEN=403;
export const NOT_FOUND = 404;
export const CONFLICT=409; 
export const UNPROCESSABLE_CONTENT=422;
export const TOO_MANY_REQUEST=429;
export const INTERNAL_SERVER_ERROR =500;
export const SERVICE_UNAVAILABLE = 503;
export const NETWORK_ERROR ="ERR_NETWORK";

export const CONSENT_ACKNOWLEDGEMENT_EN = "I have read, understood, acknowledged, and fully agreed to the policies stated above.";
export const CONSENT_ACKNOWLEDGEMENT_ES = "He leído, comprendido, aceptado y estoy completamente de acuerdo con las políticas indicadas anteriormente.";



//TOAST MESSAGES
// export const NETWORK_ERROR_MESSAGE=_t('NetworkError');
export const NETWORK_ERROR_MESSAGE='Network error occurred. Please check your internet connection';
export const TOO_MANY_REQUEST_ERROR_MESSAGE='Too Many Request';
export const SOMETHING_WENT_WRONG_ERROR_MESSAGE='Something wents wrong .Please contact admin';
export const SESSION_EXPIRE_MESSAGE='Your session has expired. Please login again.';

export const SOCIAL_SECURITY_NUMBER_FORMAT='999-99-9999';

export const ItentificationTypeOptions = [{
    label:"-Select Identification Type-",
    value:""
},{
    label:"Tribal ID",
    value:"Tribal ID"
},{
    label:"Driver's License",
    value:"Driver's License"
},{
    label:"Employer ID",
    value:"Employer ID"
},{
    label:"Health ID",
    value:"Health ID"
},{
    label:"Military ID",
    value:"Military ID"
},{
    label:"Passport ID",
    value:"Passport ID"
},{
    label:"Other",
    value:"Other",
}];


export const genderOptions = [{
    label:"-None-",
    value:""
},{
    label:'Female',
    value:'Female',
},{
    label:'Male',
    value:'Male',
},{
    label:'Unknown',
    value:'Unknown',
}];

export const EthinicityOptions = [{
    label:"-None-",
    value:""
},{
    label:'Hispanic',
    value:'Hispanic',
},{
    label:'Non-Hispanic / Latino',
    value:'Non-Hispanic / Latino',
},{
    label:'Decline',
    value:'Decline',
},{
    label:'Unknown',
    value:'Unknown',
}];


export const raceOptions = [{
    label:"-None-",
    value:""
},{
    label:'Other / Unknown',
    value:'Other / Unknown',
},{
    label:'Native American',
    value:'Native American',
},{
    label:'Asian',
    value:'Asian',
},{
    label:'Other',
    value:'Other',
},{
    label:'Caucasian',
    value:'Caucasian',
},{
    label:'Other',
    value:'Other',
},];
export const RelationShipOptions=[
    {
        label: '-Select-',
        value: '',
      },
    {
        label: 'Mother',
        value: 'mother',
      },
      {
        label: 'Father',
        value: 'father',
      },
      {
        label: 'Sibling',
        value: 'sibling',
      },
      {
        label: 'Spouse',
        value: 'spouse',
      },
      {
        label: 'Guardian',
        value: 'guardian',
      },
      {
        label: 'Other',
        value: 'other',
      }
]


//lookup api constants

export const DOCUMENT_TYPE='document-type';
export const LAB_TYPE='LabType';
export const LAB_TEST_RESULT='LabTestResult';
export const ACTIVITY_TYPE='activity_type';
export const ACTIVITY_FORM_TYPE='activity_form_type';
export const REMIND_IN='remind_in';
export const VISIT_CATEGORY='VisitCategory';
export const VISIT_STATUS = 'visit-status'
export const SPECIALTY= 'Specialty';
export const BILLING_SERVICE_TYPE='billing_service_type';
export const LAB_ORDER='lab_order';
export const REASON_FOR_LAB='reason_for_lab';
export const VACCINATION_STATUS='vaccination_status';
export const VACCINATION_NAME='vaccine_name';
export const VACCINATION_SPECIFY_REASON='vaccine_specify_reason';
export const SALES_REP='sales_rep';
export const WORK_COMP_INSURANCE='work_comp_insurance';
export const BILLING_STATUS='billing_status';
export const PAYMENT_OPTIONS='payment_options';


