import React from "react";
import { isSet } from "../../../../utils/commonUtils";

const Header = (props)=>{
    const {className, keyIndex, pagination, sortName, value, clickHandler,headerType,changeHandler } = props;    
    const headerClickHandler = () => {
        //prevent sortName 
        if (sortName) {
            const newPagination = { ...pagination };
         if (sortName === newPagination.sort_by) {
            //if already filter applied field clicked again change sort order vice versa  
            newPagination.sort_order = newPagination.sort_order === 'asc' ? 'desc' : 'asc';
            newPagination.sort_by = sortName;
        } else {
            //default sort order ascending
            newPagination.sort_order = 'asc';
            newPagination.sort_by = sortName;
        }
        clickHandler(newPagination);
        }
        
    };
    let appendClasses = [];
    if(sortName && pagination && pagination?.sort_by===sortName){
        if(pagination?.sort_order==='asc'){
            appendClasses.push('datatable-ascending');
        }else{
            appendClasses.push('datatable-descending');
        }
    }
    let styles = {
        verticalAlign:'middle'
    };
    if(props.headerType==='checkBox'){
        styles = {
            verticalAlign: 'baseline'
        }
    }
    if (props?.style) {
        styles = {
            ...styles,
            ...props?.style
        }
    }
    return(
        <>
        {
          headerType=='checkBox'?
          <th>
            <div className="form-check" style={{...styles}} >
                <input className="form-check" type="checkbox"  key={keyIndex} name={props.name} id={props.name} onClick={(e)=>changeHandler(Boolean(e.target.checked) ? 1 : 0, {e})} /></div>
          </th>
          :
            <th onClick={headerClickHandler} className={appendClasses.join(' ')} style={{...styles}}>
            {isSet(sortName,null)!==null?
              <a href="#!" key={keyIndex} className={className} >
                 {value}
             </a>
            :value}
            
         </th>
        }
        </>
        

    )
}

Header.defaultProps = {
    className:"datatable-sorter"
}
export default Header;