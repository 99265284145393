import React from 'react';

const FolderIcon = ({ size, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="folder" width="18" height="18" fill="url(#a)">
    <g>
      <path d="M7.9 13h18.72v-2a3.041 3.041 0 0 0-3.06-3h-6.48a1.088 1.088 0 0 1-.67-.23L12.4 4.65A3.091 3.091 0 0 0 10.51 4H5.06A3.035 3.035 0 0 0 2 7v17.65l2.98-9.54A3.054 3.054 0 0 1 7.9 13Z"></path>
      <path d="M29.82 15H7.9a1.047 1.047 0 0 0-1.01.7L3.23 27.39c.09.06.17.12.27.18s.18.1.27.14a2.464 2.464 0 0 0 .38.14c.08.02.15.05.23.07a3.4 3.4 0 0 0 .63.08h19.08a3.054 3.054 0 0 0 2.92-2.11l2.85-9a2.847 2.847 0 0 0-.04-1.89Z"></path>
    </g>
  </svg>
  );
};

FolderIcon.defaultProps = {
  size: 16,
  color: '#0623c1',
};

export default FolderIcon;
