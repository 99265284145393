
import React from "react";
import { isSet } from "../../utils/commonUtils";


const FieldLoader = ({isLoading,children})=>{
    return (
        <>
          {isLoading ? (
            <div className="fieldloading">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : (
            children
          )}
        </>
      );
}

export default FieldLoader;
