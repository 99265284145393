import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import AuditLogList from "./auditLogList";


const AuditLogContainer=()=>{
    return(
        <>
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<AuditLogList />} />
        
      </Routes>
      </Wrap>
      </>
    );
}
export default AuditLogContainer; 

