import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';
import { CREATED, OK } from '../utils/constant';

const CptService = {
  getCptList: async (query = {}) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`cpt?${queryString}`);
      if (response.code === 200 && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.error('Error fetching cpt:', error);
      // throw error;
    }
  },
  //Save cpt
  saveCpt: async (data) => {
    try {
      const response = await http.POST(`cpt`, data)
      if ((response.code === OK || response.code === CREATED) && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching cpt:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },

  //Update cpt
  updateClinic: wOrgIdMiddleware(async (url, clinicId, data) => {
    try {
      const response = await http.PUT(`${url}clinics/${clinicId}`, data);
      const resData = response.data;
      if (resData.code === 200 && resData.data) {
        return response.data;
      } else {
        return response;
      }

    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching cpt:', error);
      throw error;
    }
  }),
  //Delete Cpt
  deleteCpt: async (id) => {
    try {
      const response = await http.DELETE(`cpt/${id}`);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching cpt:', error);
      throw error;
    }
  },
  getCptById: async (id) => {
    try {
      const response = await http.GET(`cpt/${id}`);
      if (response.code === OK) {
        return response.data;
      }
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },
  updateCptById: async (id, data) => {
    try {
      const response = await http.PUT(`cpt/${id}`, data);
      if (response.code === OK) {
        return response.data;
      }
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  },

};

export default CptService;