import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import CompanyService from "../../services/CompanyService";
import {
  formatPhoneNumberUS,
  toQueryString,
} from "../../utils/commonUtils";
import WithRoutify from "../../hoc/WithRoutify";
import AddCompany from "./AddCompany";
import { CONFLICT } from "../../utils/constant";
import withReduxState from "../../hoc/wReduxState";
import WithRouter from "../../hoc/WithRouter";
import TableSkeleton from "../../hoc/TableSkeleton";
import { withTranslation } from "react-i18next";
import AddCompanyWizard from "./AddCompany/index";

class CompanyList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const {t} = props;
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      { label: t("Name"), key: "name", type: "", inputType: "TextInput", sortName: 'name' },
      // { label: "Assigned Clinic ", key: "assigned_clinic", type: "", inputType: "TextInput", sortName: 'clinic_id' },
      { label: "Address", key: "street_address", type: "", inputType: "TextInput", sortName:'address'  },
      { label: t("CellNumber"), key: "phone", type: "", inputType: "TextInput", sortName: 'phone' },
      { label: "Start Date", key: "start_date", type: "", inputType: "TextInput", sortName: 'start_date' },
      { label: t("Action"), key: "action", type: "Actions" },
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          status: 1000,
          data: [],
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "name",
          sort_order: "asc",
          search: {},
        },
      },
      formDataInEdit: {},
      activeEditId: null,
      organizationOption: [],
      clinicOptions: [],
      isModelOpen: false,
      organization_id: "",
    };
  }

  componentDidMount() {
    const fetchList = async () => {
      await this.fetchCompaniesList();
    };
    fetchList();
    // Code to run after the component has mounted
  }

  componentDidUpdate = (prevProps, prevState) => {
    // Code to run after the component has updated
  };

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen, activeEditId } = this.state;
    return (
      <>
        {isModelOpen ? (
          activeEditId ?
            <AddCompany
              activeEditId={activeEditId}
              modelTitle={`${this.state.activeEditId ? 'View and Update' : 'Add'} Company`}
              isOpen={this.state.isModelOpen}
              fetchCompaniesList={this.fetchCompaniesList}
              addFormHandler={this.addFormHandler}
              clinicOptions={this.state.clinicOptions}
            />
            :
            <AddCompanyWizard
              activeEditId={activeEditId}
              modelTitle={`${this.state.activeEditId ? 'View and Update' : 'Add'} Company`}
              isOpen={this.state.isModelOpen}
              fetchTableRecords={this.fetchCompaniesList}
              addFormHandler={this.addFormHandler}
              clinicOptions={this.state.clinicOptions}
            />
        ) : (
          <></>
        )}
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={()=>{this.addFormHandler(); this.setState({ activeEditId: "" }) }}
          acl={"company-create"}
          content={"Company"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex app-heading-tab mb-0 justify-content-between align-items-center">
                <h6 class="text-lg f-500 mb-0"> Company List </h6>
                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 px-1 d-flex">
                      <div className="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="Company"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="btn-group pl-2 mb-1">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                        className="btn btn-primary"
                        acl={"company-create"}
                        label={"Add Company"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                addFormHandler={() => this.addFormHandler()}
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchCompaniesList(filters)
                }
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search: filters,
    };
    this.fetchCompaniesList(queryfilters);
  };
  clearFiltersHandler = async () => {
    await this.setState({
      filters: {},
    });
    this.submitFiltersHandler();
  };
  fetchCompaniesList = async (query = {}) => {
    const { tableRecords } = this.state;
    try {
      const queryString = toQueryString({
        ...tableRecords.pagination,
        ...query,
      });
      this.props.Loader(true);
      const response = await CompanyService.getCompanies(queryString);
      const tableData = response?.data?.data?.map((company, companyIndex) => ({
        companyIndex: companyIndex,
        isHeading: false,
        rowId: company.company_uuid,
        data: {
          isActive: false,
          name: company.name,
          assigned_clinic: company?.clinic?.name || "--",
          phone:formatPhoneNumberUS(company.phone) || "--",
          start_date: company.start_date || "--",
          street_address: company.address|| "--",
          active: "Yes",
          action: [
            {
              className: "btn btn-icon btn-transparent-dark",
              iconType: "Activities",
              acl: "any",
              title: "Physical",
              // label: "Physical",
              href:
                "/company/physical/" +
                company.company_uuid +
                "/" +
                company.name,
            },
            {
              className: "btn btn-icon btn-transparent-dark",
              iconType: "Search",
              title: "View",
              type: "GridEdit",
              acl: "company-edit",
              clickHandler: (rowId, data) =>
                this.editHandler(rowId, data, company.clinic_id),
              updateHandler: () => this.updateHandler(company.company_uuid),
              onChangeFormDataInEdit: (key, val) =>
                this.onChangeFormDataInEdit(key, val),
            },
            {
              className: "btn btn-icon btn-transparent-dark",
              iconType: "Remove",
              acl: "company-delete",
              title: "Delete",
              isDelete: true,
              clickHandler: () => this.deleteHandler(company.company_uuid),
            },
          ],
        },
      }));
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            status: 200,
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: response?.data?.total,
          },
        },
      }));
    } catch (err) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200,
          },
        },
      });
      console.log("DataIsse");
      // console.error("Error fetching companies:", err);
    } finally {
      this.props.Loader(false);
    }
  };

  deleteHandler = async (companyId) => {
    CompanyService.deleteCompany(companyId)
      .then((res) => {
        console.log("res==>", res);
        //toast and show error logic
        if (res&&res.code==CONFLICT&&res.data&& res.data.errors) {
          res.data.errors.forEach((error) => {
            this.props.Toast.error(error, 2000); // Display each error for 2 seconds
        });
        }
        else{
          this.props.Toast.success("Company Deleted Successfully");
          this.fetchCompaniesList();
        }
      })
      .catch((err) => {
        //
      })
      .finally(() => {
       this.props.clearDDStateByKey('company');
      });
  };
  editHandler = (rowId, data, clinic_id) => {
    // this.props.navigate(`detail-view/${rowId}/edit`)
    console.log("rowId", rowId);
    this.setState({
      activeEditId: rowId
    }, this.addFormHandler())
  };
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}
export default WithRouter(withReduxState(WithRoutify(withTranslation()(CompanyList))));
