import React from 'react';

const FolderTreeIcon= ({size,color,fill}) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 29 29" fill={fill}>
          <path d="M16.7401 7.71642H25.304V6.80147C25.299 6.43426 25.1491 6.0839 24.8868 5.8268C24.6246 5.56971 24.2713 5.42673 23.9041 5.42906H20.9397C20.8286 5.42916 20.7208 5.39212 20.6332 5.32384L18.7987 3.89653C18.5514 3.70439 18.2473 3.5998 17.9341 3.59917H15.4409C15.0734 3.59612 14.7197 3.73886 14.4574 3.99611C14.195 4.25336 14.0452 4.60414 14.041 4.97159V13.046L15.4043 8.68168C15.4958 8.39995 15.6746 8.15461 15.9147 7.98111C16.1548 7.8076 16.4439 7.71491 16.7401 7.71642Z" fill={fill} />
          <path d="M26.7657 8.63097H16.738C16.637 8.62884 16.538 8.65865 16.455 8.71616C16.372 8.77367 16.3093 8.85593 16.2759 8.9512L14.6016 14.299C14.6427 14.3265 14.6793 14.3539 14.7251 14.3814C14.7708 14.4088 14.8074 14.4271 14.8486 14.4454C14.9047 14.4715 14.9628 14.4929 15.0224 14.5095C15.059 14.5186 15.0911 14.5324 15.1277 14.5415C15.2224 14.5627 15.3189 14.5749 15.4159 14.5781H24.1444C24.4407 14.5796 24.7297 14.4869 24.9698 14.3134C25.2099 14.1399 25.3887 13.8946 25.4802 13.6128L26.784 9.49559C26.8766 9.21371 26.8701 8.90868 26.7657 8.63097Z" fill={fill} />
          <path d="M16.7401 20.5265H25.304V19.6115C25.299 19.2443 25.1491 18.894 24.8868 18.6369C24.6246 18.3798 24.2713 18.2368 23.9041 18.2391H20.9397C20.8286 18.2392 20.7208 18.2022 20.6332 18.1339L18.7987 16.7066C18.5514 16.5145 18.2473 16.4099 17.9341 16.4092H15.4409C15.0734 16.4062 14.7197 16.5489 14.4574 16.8062C14.195 17.0634 14.0452 17.4142 14.041 17.7816V25.856L15.4043 21.4917C15.4958 21.21 15.6746 20.9647 15.9147 20.7912C16.1548 20.6177 16.4439 20.525 16.7401 20.5265Z" fill={fill} />
          <path d="M26.7657 21.441H16.738C16.637 21.4389 16.538 21.4687 16.455 21.5262C16.372 21.5837 16.3093 21.666 16.2759 21.7613L14.6016 27.1091C14.6427 27.1366 14.6793 27.164 14.7251 27.1914C14.7708 27.2189 14.8074 27.2372 14.8486 27.2555C14.9047 27.2816 14.9628 27.303 15.0224 27.3195C15.059 27.3287 15.0911 27.3424 15.1277 27.3516C15.2224 27.3727 15.3189 27.385 15.4159 27.3882H24.1444C24.4407 27.3897 24.7297 27.297 24.9698 27.1235C25.2099 26.95 25.3887 26.7046 25.4802 26.4229L26.784 22.3056C26.8766 22.0238 26.8701 21.7187 26.7657 21.441Z" ffill={fill} />
          <path d="M3.22266 3.22217L3.22266 25.3262" stroke="#0623C1" stroke-linecap="round" />
          <path d="M17.2422 12.1392L4.4319 12.1392" stroke="#0623C1" stroke-linecap="round" />
          <path d="M17.2891 25.3262L3.22284 25.3262" stroke="#0623C1" stroke-linecap="round" />
      </svg>
  );
};
FolderTreeIcon.defaultProps = {
  size: 28,
  color:'#94b6fd',
  fill:"url(#a)",
};
export default FolderTreeIcon