import React, { useEffect } from 'react';
import {useDispatch } from 'react-redux';
import {fetchBillingStatus, fetchClinic, fetchCompany, fetchVisitCategory, fetchVisitStatus} from '../store/DropDownSlice';
import { useSelector } from 'react-redux';

const WithDropdownState = (WrappedComponent) => {
  const ConnectedComponent = (props) => {
    const { visitStatus, visitCategory, clinic, company,billingStatus } = useDropdownData();    
    return <WrappedComponent
      {...props}
      visitStatus={visitStatus}
      visitCategory={visitCategory}
      clinicOptions={clinic}
      companyOptions={company}
      billingStatus={billingStatus}
      />;
  };

  return ConnectedComponent;
};

export default WithDropdownState;


const useDropdownData = () => {
  const dispatch = useDispatch();
  const visitStatus = useSelector((state) => state.dropDown.data.visitStatus);
  const visitCategory = useSelector((state) => state.dropDown.data.visitCategory);
  const clinic = useSelector((state) => state.dropDown.data.clinic);
  const company = useSelector((state) => state.dropDown.data.company);
  const billingStatus = useSelector((state) => state.dropDown.data.billingStatus);

  useEffect(() => {
    if (!visitStatus.length) {
      dispatch(fetchVisitStatus());
    }
    if (!visitCategory.length) {
      dispatch(fetchVisitCategory());
    }
    if (!company.length) {
      dispatch(fetchCompany());
    }
    if (!clinic.length) {
      dispatch(fetchClinic());
    }
    if (!billingStatus.length) {
      dispatch(fetchBillingStatus());
    }
  }, [dispatch, visitStatus.length, visitCategory.length, company.length, clinic.length,billingStatus.length]);

  return { visitStatus, visitCategory, clinic, company,billingStatus };
};

