import React from "react";
import Wrap from "../../../hoc/Wrap";
import { isSet } from "../../../utils/commonUtils";
import TableData from "./TableData"; 

const TableHeader=(props)=>{
    const {tableHeaders,fetchTableRecords,pagination}=props;
    console.log('tableHeaders',tableHeaders);
    
    return (
        <Wrap>
         <thead>
              <tr>
                {tableHeaders.map((item, itemIndex) => (
                    <TableData 
                    value={item.label} 
                    keyIndex={itemIndex} 
                    key={itemIndex} 
                    headerType={item.type}
                    type={'THeader'} 
                    name={item.name} 
                    isSort={isSet(item.isSort,props.isSort)}
                    sortName={item?.sortName}
                    clickHandler={fetchTableRecords} 
                    pagination={pagination}
                    changeHandler={item.changeHandler}
                    style={item?.style}
                    />
                ))}
              </tr>
            </thead>
        </Wrap>
    );
}

TableHeader.defaultProps ={
    isSort:false
}
export default TableHeader;