import { getCurrentDate } from "../../../utils/commonUtils"

export const getCurrentStepInputFields = (args) => {
    const { currentStep, clinicOptions, stateList, workCompInsuranceOptions, isCopyFromCompanyChecked, organizationListOptions, is_multiple_organization, salesRepOptions } = args;
    switch (currentStep) {
        case 1:
            return [
                is_multiple_organization ? {
                    title: "Organization Details",
                    data: [
                        {
                            type: "SelectSearchList",
                            label: "Organization",
                            options: organizationListOptions,
                            placeholder: "",
                            className: "form-control form-control-solid",
                            name: "organization",
                            required: true,

                        },
                    ],
                } : null,
                {
                    title: "Company - Address & Contact",
                    data: [
                        {
                            type: "TextInput",
                            label: "Name",
                            name: "name",
                            placeholder: "Name",
                            className: "form-control form-control-solid",
                            isFirstLetterCaps: true,
                            required: true,
                        },
                        {
                            type: "TextInput",
                            label: "Code",
                            name: "code",
                            placeholder: "Code",
                            className: "form-control form-control-solid",
                            allUpperCase: true,
                            max:5,
                          },
                        {
                            type: "Address",
                            label: "Address",
                            name: "address",
                            placeholder: "Address",
                            required: true,
                            isFirstLetterCaps: true,
                        },
                        {
                            type: "TextInput",
                            label: "City",
                            name: "city",
                            placeholder: "City",
                            isFirstLetterCaps: true,
                            required: true,
                        },
                        {
                            type: "SelectList",
                            options: stateList,
                            label: "State",
                            name: "state",
                            placeholder: "State",
                            isFirstLetterCaps: true,
                            required: true,
                        },
                        {
                            type: "ZipCode",
                            label: "Zip+4",
                            name: "postal_code",
                            placeholder: "zip+4",
                            required: true,
                        },
                        {
                            type: "TextInput",
                            label: "Location",
                            name: "location",
                            placeholder: "Location",
                            isFirstLetterCaps: true,
                        },
                        {
                            type: "TextInput",
                            label: "County",
                            name: "county",
                            placeholder: "County",
                            isFirstLetterCaps: true,
                        },
                        {
                            type: "Phone",
                            label: "Phone",
                            name: "phone",
                            className: "form-control form-control-solid",
                            placeholder: "Phone",
                            required: true,
                        },
                        {
                            type: "Phone",
                            label: "Fax",
                            name: "fax",
                            placeholder: "Fax",
                            max: 10
                        },
                        {
                            type: "TextInput",
                            label: "General Email",
                            // col:'col-6',
                            name: "general_email",
                            placeholder: "Email",
                        },
                        {
                            type: "MultiSelectDropDown",
                            col: 'col-12',
                            options: clinicOptions,
                            label: "Assign Clinic",
                            name: "clinic_id",
                            placeholder: "",
                            required: true,
                            hideValidationMsg:true
                        },
                    ],
                },
            ]
        case 2:
            return [
                {
                    title: "Company - ID",
                    data: [
                        {
                            type: "",
                            label: "EMR Interface ID",
                            name: "emr_interface_id",
                            placeholder: "EMR Interface ID",
                            // required: true,
                        },
                        {
                            type: "TextInput",
                            label: "Nickname",
                            name: "nickname",
                            placeholder: "Nickname",
                            max: 5,
                        },
                        {
                            type: "Number",
                            label: "Federal Tax ID",
                            name: "tax_id",
                            placeholder: "Federal Tax ID",
                        },
                        {
                            type: "Date",
                            label: "Start Date",
                            name: "start_date",
                            placeholder: "Start Date",
                            value: getCurrentDate(),
                        },
                    ],
                },
                {
                    title: "Company - Insurance",
                    data: [
                        {
                            type: "SelectList",
                            options: workCompInsuranceOptions,
                            label: "Work Comp Insurance",
                            name: "work_comp_insurance",
                            placeholder: "Work Comp Insurance",
                        },
                        {
                            type: "TextInput",
                            label: "Work Comp Policy Number",
                            name: "work_comp_policy_number",
                            placeholder: "Work Comp Policy Number",
                        },
                        {
                            type: "Date",
                            label: "Work Comp Policy Expires",
                            name: "work_comp_policy_expires",
                            placeholder: " Work Comp Policy Expires",
                            isFirstLetterCaps: true,
                        },
                    ],
                },
            ]
        case 3:
            return [
                {
                    title: "Employer - Results Notifications",
                    data: [
                        {
                            type: "Tag",
                            tagInputType: 'Email',
                            label: "Physical Results Email",
                            name: "physical_results_email",
                            placeholder: "Physical Results Email",
                            className: "form-control form-control-solid",
                        },
                        {
                            type: "Tag",
                            tagInputType: 'Email',
                            label: "Drug Screen Results Email",
                            name: "drug_screen_results_email",
                            placeholder: "Drug Screen Results Email",
                        },
                        {
                            type: "Tag",
                            tagInputType: 'Email',
                            label: "Lab Results Email",
                            name: "lab_result_email",
                            placeholder: "Lab Results Email",
                        },

                        {
                            type: "Tag",
                            tagInputType: 'Phone',
                            label: "Physical Results SMS Text",
                            name: "physical_results_sms_text",
                            placeholder: "Physical Results SMS Text",
                            isNumber: true,
                            maxSize: 10,
                        },
                        {
                            type: "Tag",
                            tagInputType: 'Phone',
                            label: "Drug Screen Results SMS Text",
                            name: "drug_screen_results_sms_text",
                            placeholder: " Drug Screen Results SMS Text",
                            isNumber: true,
                            maxSize: 10,
                        },
                        {
                            type: "Tag",
                            tagInputType: 'Phone',
                            label: "Lab Results SMS Text",
                            name: "lab_result_sms_text",
                            placeholder: "Lab Results SMS Text",
                            isNumber: true,
                            maxSize: 10,
                        },

                        {
                            type: "Tag",
                            tagInputType: 'Email',
                            label: "Missing Claim Email",
                            name: "missing_claim_email",
                            placeholder: "Missing Claim Email",
                        },
                        {
                            type: "Tag",
                            tagInputType: 'Phone',
                            label: "Missing Claim SMS Text",
                            name: "missing_claim_sms_text",
                            placeholder: "Missing Claim SMS Text",
                            isNumber: true,
                            maxSize: 10,
                        },
                    ],
                },
                {
                    title: "Employee - Results Notifications",
                    data: [
                        {
                            type: "YesOrNo",
                            label: "Send Physical Results to Employees",
                            name: "send_physical_results_to_employees",
                            placeholder: "Send Physical Results to Employees",
                            required: true,
                        },
                        {
                            type: "YesOrNo",
                            label: "Send Drug Screen Results to Employees",
                            name: "send_drug_screen_results_to_employees",
                            placeholder: "Send Drug Screen Results to Employees",
                            required: true,
                        },
                        {
                            type: "YesOrNo",
                            label: "Send Lab Results to Employees",
                            name: "send_lab_results_to_employees",
                            placeholder: "Send Lab Results to Employees",
                            required: true,
                        },

                    ],
                },
                {
                    title: "Company - Invoice Notifications",
                    data: [
                        {
                            type: "Tag",
                            tagInputType: 'Email',
                            label: "Invoice Email",
                            name: "invoice_email",
                            placeholder: "Invoice Email",
                        },
                        {
                            type: "Tag",
                            tagInputType: 'Phone',
                            label: "Invoice SMS Text",
                            name: "invoice_sms_text",
                            placeholder: "Invoice SMS Text",
                            isNumber: true,
                            maxSize: 10,
                        },
                    ],
                },
            ]
        case 4:
            return [
                {
                    title: "Company - Accounts Payable Address",
                    data: [
                        {
                            type: 'Checkbox',
                            isLabelAppend: true,
                            className: "form-check-input",
                            name: "copy_from_company",
                            id: "copy_from_company",
                            label: 'Copy same Address from Company',
                            value: { isCopyFromCompanyChecked },
                            col: 'col-12',
                        },
                        {
                            type: "Address",
                            label: "Accounts Payable Address",
                            name: "accounts_payable_address",
                            placeholder: "Accounts Payable Address",
                            required: true,
                            isFirstLetterCaps: true,
                        },
                        {
                            type: "TextInput",
                            label: "Accounts Payable City",
                            name: "accounts_payable_city",
                            placeholder: "Accounts Payable City",
                            required: true,
                            isFirstLetterCaps: true,
                        },
                        {
                            type: "SelectList",
                            options: stateList,
                            label: "Accounts Payable State",
                            name: "accounts_payable_state",
                            placeholder: "Accounts Payable State",
                            required: true,
                        },
                        {
                            type: "ZipCode",
                            label: "Accounts Payable Zip+4",
                            name: "accounts_payable_postal_code",
                            placeholder: "Accounts Payable Zip+4",
                            required: true,
                        },
                        {
                            type: "TextInput",
                            label: "Accounts Payable Contact Name",
                            name: "accounts_payable_contact_name",
                            placeholder: "Accounts Payable Contact Name",
                            required: true,
                            isFirstLetterCaps: true,
                        },
                        {
                            type: "Phone",
                            label: "Accounts Payable Contact Phone",
                            name: "accounts_payable_contact_phone",
                            placeholder: "Accounts Payable Contact Phone",
                            required: true,
                        },
                    ],
                },
                {
                    title: "Company - Employer Special Instructions",
                    data: [
                        {
                            col: 'col-12',
                            type: "Editor",
                            label: "Employer Special Instructions",
                            name: "employer_special_instructions",
                            placeholder: "Employer Special Instructions",
                        },
                    ],
                },
                {
                    title: "Company - Contracts & Pricing",
                    data: [
                        {
                            type: "SelectList",
                            options: salesRepOptions,
                            label: "Sales Rep",
                            name: "sales_rep",
                            placeholder: "Sales Rep",
                            required: true,

                        },
                        {
                            type: "Number",
                            label: "Contracted Pricing",
                            name: "contracted_pricing",
                            placeholder: "Contracted Pricing",
                            required: true,
                            decimalAllowed:true,
                            max:12
                        },
                        {
                            type: "TextInput",
                            label: "Purchase Order",
                            name: "purchase_order",
                            placeholder: "Purchase Order",
                        },
                    ],
                },
            ]
        default:
            return []
    }
}

