import ClinicDepartmentService from "../../../../../../services/ClinicDepartmentService";
import { LabResultCalcualtion, fetchTimeInterval, getCurrentDate, getCurrentTime, getLookupByKey } from "../../../../../../utils/commonUtils";
import { LAB_TEST_RESULT, VACCINATION_STATUS } from "../../../../../../utils/constant";
import DropDownService from "../../../../../../services/DropDownService";

export const fetchReasonForLabList = async (val, props, callback = () => { }) => {
  // const {employeeDetails} = props;
  let optionArr = [];
  try {
    const reasonForLabs = await getLookupByKey('reason_for_lab');
    optionArr = reasonForLabs;
    callback(optionArr);
  } catch (e) {

  }
  return optionArr;
}

export const fetchClinicDepartment = (val, props, callback = () => { }) => {
  //{search_query:val}
  let optionArr = [];
  ClinicDepartmentService.getClinicDepartmentByClinicId(null,props.employeeDetails.clinic_id)
    .then((res) => {
      // console.log("clinicLocationList", res);
      res?.map((clinic, locationIndex) => {
        optionArr.push({
          label: clinic.facility_name,
          value: clinic.id,
          data: clinic,
          index: locationIndex
        });
      });
      callback(optionArr);

    })
    .catch((error) => {
      console.error(error);
    });
  return optionArr;
}


export const fetchLabChoiceList = async (val, props, callback = () => {}) => {
  //{search_query:val}
  let optionArr = [];
  try {
    const query = { clinic_id: props.employeeDetails.clinic_id };
    const res = await DropDownService.getLabsforClinic(null, query);
    if (Array.isArray(res) && res.length > 0) {
      res.forEach((item, itemIndex) => {
        optionArr.push({
          label: item.name,
          value: item.lab_id,
          data: item,
          index: itemIndex
        });
      });
    }
    callback(optionArr);
  } catch (error) {
    console.error('Error fetching lab list:', error);
  }

  return optionArr;
};
export const fetchVaccinationStatus = async (val, props, callback = () => { }) => {
  // const {employeeDetails} = props;
  let optionArr = [];
  try {
    const vaccination_status = await getLookupByKey(VACCINATION_STATUS);
    optionArr = vaccination_status;
    callback(vaccination_status);
  } catch (e) {

  }
  return optionArr;
}
export const fetchTestResult = async (val, props, callback = () => { }) => {
  // const {employeeDetails} = props;
  let optionArr = [];
  try {
    const labTestResult = await getLookupByKey(LAB_TEST_RESULT);
    optionArr = labTestResult;
    callback(labTestResult);
  } catch (e) {

  }
  return optionArr;
}


// export const fetchLabOrderList = (val,props,callback=()=>{})=>{
//   //{search_query:val}
//   const {formData} = props;
//   console.log('formData',formData);
//   let optionArr =[];
//   LabOrderdService.getLabOrder(null,formData?.lab_id) 
//   .then((res) => {
//     res.data?.data?.map((item, itemIndex) => {
//       optionArr.push({
//         label:item.name,
//         value:item.lab_id,
//         data:item,
//         index:itemIndex
//       });
//     });
//     callback(optionArr);

//   })
//   .catch((error) => {
//     console.error(error);
//   });
//   return optionArr;
// }

export const Laboratory = (props) => {

console.log('props',props);


  let charts = [{
    type: 'Grid',
    col: 'col-md-3',
    data: [{
      type: "PatientDetails",
      employeeDetails: props.employeeDetails,
      visitDetails: props.visitDetails
    }, {
      type: "CompanyDetails",
      companyDetails: props.companyDetails
    }],
  },
  {
    type: 'Tab',
    col:'col-md-9',
    data:[
      {
        id:'lab_order',
        data: [
          {
            label: "Lab Details",
            fields: [
              {
                label: "Lab ID",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "random_lab_id",
                id: "random_lab_id",
                options: [],
                type: 'NonEdit',
              },
              {
                type: "Date",
                label: "Test Date",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "test_date",
                required: true,
              },
              {
                type: "SelectSearchList",
                label: "Test Time",
                placeholder: "Test Time",
                isAsync: true,
                options: [{ 'label': getCurrentTime(), value: getCurrentTime() }],
                loadCallBack: (val, callback) => fetchTimeInterval(val, callback),
                className: "form-control form-control-solid",
                name: "test_time",
                required: true,
              },
              {
                options: [],
                label: "Lab Order",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "lab_order_id",
                type: 'SelectSearchList',
                isAsync: true,
                loadCallBack: (val, callback) => props.fetchLabOrderList(val, props, callback),
                required:true,
    
              },
              {
                type: "Date",
                label: "Result Date",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "result_date",
              },
              {
                options: [],
                label: "Test Result",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "test_result",
                type: 'SelectSearchList',
                isAsync: true,
                loadCallBack: (val, callback) => fetchTestResult(val, props, callback),
              },
            ]
          },
          {
            label: `Activity Details ${props.activityName}`,
            fields: [
              {
                type: "TextInput",
                label: "Accession",
                placeholder: "Accession",
                className: "form-control form-control-solid",
                name: "accession",
              },
              {
                type: "TextInput",
                label: "Sample ID",
                placeholder: "Sample ID",
                className: "form-control form-control-solid",
                name: "sample_id",
              },
              {
                type: "TextInput",
                label: "Test Range",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "test_range",
              },
              {
                label: "Lab Choice",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "lab_id",
                id: "lab_id",
                options: [],
                type: 'SelectSearchList',
                isAsync: true,
                loadCallBack: (val, callback) => fetchLabChoiceList(val, props, callback),
                required: true,
              },
              {
                options: [],
                label: "Clinic Department",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "clinic_department_id",
                type: 'SelectSearchList',
                isAsync: true,
                loadCallBack: (val, callback) => fetchClinicDepartment(val, props, callback),
                // required: true,
              },
              {
                type: "SelectSearchList",
                options: [],
                isAsync: true,
                loadCallBack: (val, callback) => fetchVaccinationStatus(val, props, callback),
                label: "Vaccinated",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "vaccination_status",
              },
              {
                type: "Date",
                label: "Received Date",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "received_date",
              },
              {
                type: "SelectSearchList",
                label: "Received Time",
                placeholder: "Test Time",
                isAsync: true,
                options: [{ 'label': getCurrentTime(), value: getCurrentTime() }],
                loadCallBack: (val, callback) => fetchTimeInterval(val, callback),
                className: "form-control form-control-solid",
                name: "received_time",
              },
              {
                type: "TextInput",
                label: "Result Value",
                placeholder: "Result Value",
                className: "form-control form-control-solid",
                name: "result_value",
              },
              {
                type: "TextInput",
                label: "Alternate ID",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "alternate_id",
              },
              {
                type: "TextInput",
                label: "Units",
                placeholder: "Units",
                className: "form-control form-control-solid",
                name: "units",
              },
              {
                type: "TextInput",
                label: "Flag",
                placeholder: "Flag",
                className: "form-control form-control-solid",
                name: "flag",
              },
              {
                type: "TextInput",
                label: "Reference Range",
                placeholder: "Reference Range",
                className: "form-control form-control-solid",
                name: "reference_range",
              },
              {
                type: "TextArea",
                label: "Notes",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "notes",
              },
              {
                type: "TextArea",
                label: "Generic Message",
                placeholder: "",
                className: "form-control form-control-solid",
                name: "generic_message",
              },
              {
                type: "TextArea",
                label: "Lab Message",
                placeholder: "Lab Message",
                className: "form-control form-control-solid",
                name: "lab_message",
              },
              {
                options: props.reasonForLabs,
                label: "Reason for lab (ICD-10)",
                name: "reason_for_lab",
                id: "reason_for_lab",
                type: 'MultiSelectDropDown',
                isToogle:true
              },
              {
                label: 'Status',
                type: 'SelectList',
                options: [
                  { label: "PENDING", value: "PENDING" },
                  { label: "PASSED", value: "PASSED" },
                  { label: "FAILED", value: "FAILED" },

                ],
                name: 'status',
              },
            ]
          },
        ]
      },
      {
        id:'lab_panel',
        data:[ {
            label:'Lab Panel',
            fields:[{
                type:'LabPanel',
                employeeDetails:props.employeeDetails,
                visitDetails:props.visitDetails,
                formData:props.formData,
                fetchLabOrderList:props.fetchLabOrderList,
                fetchRecords:props.fetchPatientChartDetials
            }]
        }],
    },
    ],
    
  }];
  const defaultValue = {
    status: 'Not Started',
    suggested_status: 'Not Started',
    test_date:getCurrentDate(),
    test_time:getCurrentTime(),
    result_date:getCurrentDate(),
    // reason:'Pre-employment'
  };
  const buttons = {
    label: true,
    labreport:true,
  }
  const headers = [{
    type:'Tab',
    data:[{
        label:'Lab order',id:'lab_order'
    },
    {
        label:'Lab Panel',id:'lab_panel'
    },
  ]
}];
  return { buttons, charts, defaultValue, headers,  computationFunction: (formData, key) => computationRules(formData, key) };
}
const computationRules = (formData, key) => {
  let returnResult = {};
  if (key=='test_result') {
    const {status}=LabResultCalcualtion(formData['test_result']);
   
    if(status=='NEGATIVE'){
      returnResult['status']='PASSED';
    }else if(status=='POSITIVE'){
      returnResult['status']='FAILED';
    }else{
      returnResult['status']=status;
    }
    
  }
  returnResult = {
    ...returnResult,
  }
  return returnResult;
}