import http from './http';
// import { wOrgIdMiddleware } from '../utils/commonUtils';

const AuditLogService = {
    
    getAuditLogList:(async(query)=>{
        const queryString = new URLSearchParams(query).toString();
        const response = await http.GET(`audit-log?${queryString}`);
        console.log('Audit log response:', response.data);
        return response.data;  
    }),
};

export default AuditLogService;