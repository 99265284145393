import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactJson from 'react-json-view';

const JsonViewModal = (props) => {
    const { data, show,modelToggleHandler} = props;
    const [jsonData,setJsonData]=useState(null);

    //
    useEffect(() => {
      try {
        let parsedData = JSON.parse(data); //parse json before render error handle
        setJsonData(parsedData);
      } 
      catch (error) {
        setJsonData(null);
        console.error("Invalid JSON:", error);
      }
    }, [data]);    

    return (
         <Modal
         show={true}
         onHide={modelToggleHandler}
         size="xl"
         dialogClassName="hResponsive"
         contentClassName="h-100"
         backdrop="static"
         keyboard={false}
       >
         <div className="sh_modalBox">
           <Modal.Header closeButton>
             <Modal.Title>
               <h6 className="font-weight-bold text-lg mb-0">
                 Json Viewer
               </h6>
             </Modal.Title>
           </Modal.Header>
           <Modal.Body>
            {jsonData? (
              <ReactJson
                src={jsonData}
                theme={'Solarized'}
                enableClipboard={false}
                name={false}
                style={{ overflowX: 'auto' }}
                displayDataTypes={false}
                displayObjectSize={false}
                // indentWidth={3}
                // iconStyle={'triangle'}
                //sortKeys={true}
                // displayArrayKey={true}
              />
            ) : (
              <div></div>
            )}
           </Modal.Body>
 
           <Modal.Footer className="d-flex justify-content-center">
           <Button variant="secondary" onClick={modelToggleHandler}>
                    Close
                </Button>
           </Modal.Footer>
         </div>
       </Modal>
    );
};

export default JsonViewModal;
