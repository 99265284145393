import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BILLING_STATUS, VISIT_CATEGORY, VISIT_STATUS } from '../utils/constant';
import { getLookupByKey } from '../utils/commonUtils';
import DropDownService from '../services/DropDownService';
import LookupService from '../services/LookupService';

const DEFAULT_STATES = {
    company: [],
    clinic: [],
    visitStatus: [],
    visitCategory: [],
    billingStatus:[],
};
const DropDownSlice = createSlice({
    name: 'dropdown',
    initialState: {
        data:DEFAULT_STATES,
        loading: false, 
        error: null,
    },
    reducers: {
        resetDD: (state) => {
            state.data =DEFAULT_STATES; 
        },
        clearDDStateByKey: (state, action) => {
            const key = action.payload;
            if (state.data[key]) {
                state.data[key] = [];
            }
        },
    },
    extraReducers: (builder) => {
        // Handle Visit Status
        builder.addCase(fetchVisitStatus.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchVisitStatus.fulfilled, (state, action) => {
            state.data.visitStatus = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchVisitStatus.rejected, (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        });

        // Handle Visit category
        builder.addCase(fetchVisitCategory.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchVisitCategory.fulfilled, (state, action) => {
            state.data.visitCategory = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchVisitCategory.rejected, (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        });

        // Handle Billing Status category
        builder.addCase(fetchBillingStatus.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchBillingStatus.fulfilled, (state, action) => {
            state.data.billingStatus = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchBillingStatus.rejected, (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        });

        // Handle  Company
        builder.addCase(fetchCompany.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchCompany.fulfilled, (state, action) => {
            state.data.company = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchCompany.rejected, (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        });

        // Handle  Clinic
        builder.addCase(fetchClinic.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchClinic.fulfilled, (state, action) => {
            state.data.clinic = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchClinic.rejected, (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        });

    },
});

// export actions and selector
export const { resetDD,clearDDStateByKey } = DropDownSlice.actions;
export default DropDownSlice.reducer;


export const fetchVisitStatus = createAsyncThunk(
    'dropdown/fetchVisitStatus',
    async () => {
        const data = await LookupService.getLookupByKey(VISIT_STATUS);
        let visitStsArr = [{ label: '-Select-', value: '' }];
        data.forEach((item) => {
            visitStsArr.push({
                label: item.value,
                value: item.value
            });
        });
        return visitStsArr;
    }
);
export const fetchVisitCategory = createAsyncThunk(
    'dropdown/fetchVisitCategory',
    async () => {
        const data = await LookupService.getLookupByKey(VISIT_CATEGORY);
        let visitCatArr = [{ label: '-Select-', value: '' }];
        data.forEach((item) => {
            visitCatArr.push({
                label: item.value,
                value: item.value
            });
        });
        return visitCatArr;
    }
);
export const fetchCompany = createAsyncThunk(
    'dropdown/fetchCompany',
    async () => {
        const response=await DropDownService.getCompanies(null);
        let companiesOptions = [{ label: "-Select Company-", value: "" }];
        response.forEach((company) => {
          companiesOptions.push({
            label: company.name,
            value: company.company_uuid,
          });
        });
        return companiesOptions;
    }
);
export const fetchClinic = createAsyncThunk(
    'dropdown/fetchClinic',
    async () => {
        const clinicList = await DropDownService.getClinicList(null);
        let options = [{ label: "-Select Clinic-", value: "" }];
        clinicList.forEach((clinic, clinicIndex) => {
            options.push({
                label: clinic.name,
                value: clinic.clinic_id,
            });
        });
        return options;
    }
);
export const fetchBillingStatus = createAsyncThunk(
    'dropdown/fetchBillingStatus',
    async () => {
        const data = await LookupService.getLookupByKey(BILLING_STATUS);
        let StatusArr = [{ label: '-Billing Status-', value: '' }];
        data.forEach((item) => {
            StatusArr.push({
                label: item.value,
                value: item.value
            });
        });
        return StatusArr;
    }
);
